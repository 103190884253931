import {defineStore} from 'pinia';


export const useSystemStore = defineStore('system_store', {
    state: () => ({
        isLoading:false,
    }),

    getters:{
        getLoading: (state) => state.isLoading
    },

    actions:{
        setLoading(){
            this.isLoading = true;
        },
        removeLoading(){
            this.isLoading = false;
        }

    },

    persist: false,
})